
import { defineComponent } from "vue";
import { useQuizStore } from "@/store/_DataStore";

import { PageName } from "@/utils/_Constants";

export default defineComponent({
  name: "Interimscore",
  data() {
    const store = useQuizStore();
    return {
      store: store as any,
      scoreA: 0 as number,
      scoreB: 0 as number,
    };
  },
  computed: {
    player1Avatar(): string {
      return URL.createObjectURL(this.store.players.playerA.file);
    },
    player2Avatar(): string {
      return URL.createObjectURL(this.store.players.playerB.file);
    },
  },
  async created() {
    const promoToken = this.$route.query.promoToken;

    if (promoToken != "reMJ7oE8Fx8c") {
      this.$router.push("/");
    }

    try {
      const res = await this.axios.get(
        `${process.env.VUE_APP_API_URL}/api/quiz/session/${this.store.quizSessionId}/results`,
        {
          headers: {
            Authorization: `${process.env.VUE_APP_API_SECRET}`,
          },
        }
      );

      const data = res.data.data.result;
      this.scoreA = data.scoreA;
      this.scoreB = data.scoreB;
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    next() {
      this.$router.push({
        name: PageName.QUESTIONSTATUS,
        params: { halfway: "true" },
        query: {promoToken: "reMJ7oE8Fx8c"}
      });
    },
  },
});
