
import { defineComponent } from "vue";
import { useQuizStore } from "@/store/_DataStore";

export default defineComponent({
  name: "Startscreen",
  data() {
    return {
      status: "" as string,
      pollInterval: null as any,
    };
  },
  methods: {
    async waitingStatus() {
      try {
        const res = await this.axios.get(
          `${process.env.VUE_APP_API_URL}/api/quiz/session/current/status/0?sb=true`
        );

        const status = res.data.data.status;
        if (status == "qr") {
          this.status = "qr";
          this.waitingStatus();
        } else if (status == "ended") {
          this.status = "ended";
          this.waitingStatus();
        } else if (status == "question") {
          this.$router.push("/tv/question");
        } else if (status == "interimResult") {
          this.status = "interimResult";
          this.waitingStatus();
        } else {
          const store = useQuizStore();
          store.$patch({
            tvAvatarOne: res.data.data.players.playerA.image,
            tvNameOne: res.data.data.players.playerA.name,
          });
          store.$patch({
            tvAvatarTwo: res.data.data.players.playerB.image,
            tvNameTwo: res.data.data.players.playerB.name,
          });
          this.waitingStatus();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.waitingStatus();
  },
});
