
import { defineComponent } from "vue";
import { useQuizStore } from "@/store/_DataStore";
import TvPulseTopRIghtVue from "@/components/TvPulseTopRIght.vue";

export default defineComponent({
  name: "QuestionTV",
  components: {
    TvPulseTopRIghtVue
  },
  data() {
    return {
      status: "question" as string,
      pollInterval: null as any,
      question: '' as string,
      avatarOne: '' as string,
      avatarTwo: '' as string,
      progress: 0,
      currentQuestion: {
        id: null
      },
    };
  },
   created() {
    this.getQuestion();
    const store = useQuizStore();
    this.avatarOne = store.tvAvatarOne;
    this.avatarTwo = store.tvAvatarTwo;
  },
  unmounted() {
    clearInterval(this.pollInterval);
  },
  methods: {
     getQuestion() {
      this.pollInterval = setInterval(async () => {
        try {
        const res = await this.axios.get(
            `${process.env.VUE_APP_API_URL}/api/quiz/session/current/status/0?sb=true`
          );
          const store = useQuizStore();
          const status = res.data.data.status;

          
          console.log(status);
          if(status == 'question' && this.currentQuestion.id !== res.data.data.question.id){
            this.status == 'question';
            this.question = res.data.data.question.question;
            const data = res.data.data.question.id;
            this.currentQuestion.id = data;
            store.countQuestion++;
            this.progress = store.countQuestion;
          }
          else if (status == "interimResult" && store.countQuestion != 6) {
            this.$router.push("/tv/interim");
          }
          else if (status == "ended"){
            this.status == "ended";
            store.countQuestion = 0;
            this.$router.push("/tv/winner");
          } 
        } catch (error) {
          console.log(error); 
        }
      }, 500) 
    },
 },
});
