
import { defineComponent } from "vue";
import { useQuizStore } from "@/store/_DataStore";
import Redpulse from "@/components/Redpulse.vue";

export default defineComponent({
  name: "Halfway",
  components: {
    Redpulse,
  },
  data() {
    return {
      pollInterval: 0 as number,
    };
  },
  created() {
    this.checkQuestion();
  },
  methods: {
    checkQuestion() {
      const store = useQuizStore();
      this.pollInterval = setInterval(async () => {
        try {
          const res = await this.axios.get(
            `${process.env.VUE_APP_API_URL}/api/quiz/session/${store.quizSessionId}/status/${store.playerId}`
          );

          const status = res.data.data.status;

          if (status == "question") {
            clearInterval(this.pollInterval);
            this.$router.push({
              name: "Question",
              params: { halfway: "true" },
            });
          }
        } catch (error) {
          console.log(error);
        }
      }, 500);
    },
  },
});
