export enum PageName {
  HOME = "Home",
  
  //TV screens 
  STARTSCREEN = "Startscreen",
  QUESTIONTV = "QuestionTV",
  INTERIMTV = "InterimTV",
  WINNERTV = "WinnerTV",

  //Desktop page
  CONTROLPANEL = "Controlpanel",

  //Mobile pages
  REGISTER = "Register",
  WAITSCREEN = "Waitscreen",
  QUESTION = "Question",
  HALFWAY = "Halfway",
  RESULT = "Result",
  LEADEBOARD = "Leaderboard",
  
  //Tablet pages
  STARTUP = "Startup",
  PARTICIPANTS = "Participants",
  QUESTIONSTATUS = "QuestionStatus",
  INTERIMSCORE = "Interimscore",
  WINNER = "Winner"
}