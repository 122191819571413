
import { defineComponent } from "vue";
import Greypulse from "@/components/Greypulse.vue";
import { useQuizStore } from "@/store/_DataStore";
import { IPlayerData } from "@/interfaces/_ICore";

export default defineComponent({
  name: "Leaderboard",
  components: {
    Greypulse,
  },
  data() {
    return {
      players: [] as IPlayerData[],
      name: '',
      score: '',
    };
  },
  computed: {
    sortedScores(): IPlayerData[] {
      return this.players
        .sort((a: any, b: any) => (a.score > b.score ? -1 : 1))
        .slice(0, 20);
    },
  },
  created() {
    const store = useQuizStore();
    this.axios
      .get(
        `${process.env.VUE_APP_API_URL}/api/quiz/scores/?playerId=${store.playerId}`
      )
      .then((response) => {
        const data = response.data.data;
        this.name = store.myPlayer.name;
        this.score = store.playerScore;
        this.players = data.players;
      })
      .catch((error) => {
        console.log(error);
      });
  },
});
