import { PageName } from '@/utils/_Constants';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

//TV pages
import Startscreen from '@/views/tv/Startscreen.vue'
import QuestionTV from '@/views/tv/QuestionTV.vue'
import InterimTV from '@/views/tv/InterimTV.vue'
import WinnerTV from '@/views/tv/WinnerTV.vue'

//Desktop page
import Controlpanel from '@/views/desktop/Controlpanel.vue'

//Mobile pages
import Register from '@/views/mobile/Register.vue'
import Waitscreen from '@/views/mobile/Waitscreen.vue'
import Question from '@/views/mobile/Question.vue'
import Halfway from '@/views/mobile/Halfway.vue'
import Result from '@/views/mobile/Result.vue'
import Leaderboard from '@/views/mobile/Leaderboard.vue'

//Tablet pages
import Startup from '@/views/tablet/Startup.vue'
import Participants from '@/views/tablet/Participants.vue'
import QuestionStatus from '@/views/tablet/Question.vue'
import Interimscore from '@/views/tablet/Interimscore.vue'
import Winner from '@/views/tablet/Winner.vue'


const routes: Array<RouteRecordRaw> = [
  //Tv screens
  {
    path: '/tv',
    name: PageName.STARTSCREEN,
    component: Startscreen
  },
  {
    path: '/tv/question',
    name: PageName.QUESTIONTV,
    component: QuestionTV
  },
  {
    path: '/tv/interim',
    name: PageName.INTERIMTV,
    component: InterimTV
  },
  {
    path: '/tv/winner',
    name: PageName.WINNERTV,
    component: WinnerTV
  },
  //Mobile screens
  {
    path: '/',
    name: PageName.REGISTER,
    component: Register
  },
  {
    path: '/waitscreen',
    name: PageName.WAITSCREEN,
    component: Waitscreen
  },
  {
    path: '/question',
    name: PageName.QUESTION,
    component: Question
  },
  {
    path: '/halfway',
    name: PageName.HALFWAY,
    component: Halfway
  },  
  {
    path: '/result',
    name: PageName.RESULT,
    component: Result
  },
  {
    path: '/leaderboard',
    name: PageName.LEADEBOARD,
    component: Leaderboard
  },

  //Tablet screens
  {
    path: '/tablet',
    name: PageName.STARTUP,
    component: Startup,
    props: route => ({
      promoToken: route.query.promoToken,
    })
  },
  {
    path: '/tablet/participants',
    name: PageName.PARTICIPANTS,
    component: Participants,
    props: route => ({
      promoToken: route.query.promoToken,
    })
  },
  {
    path: '/tablet/question',
    name: PageName.QUESTIONSTATUS,
    component: QuestionStatus,
    props: route => ({
      promoToken: route.query.promoToken,
    })
  },
  {
    path: '/tablet/interimscore',
    name: PageName.INTERIMSCORE,
    component: Interimscore,
    props: route => ({
      promoToken: route.query.promoToken,
    })
  },
  {
    path: '/tablet/winner',
    name: PageName.WINNER,
    component: Winner,
    props: route => ({
      promoToken: route.query.promoToken,
    })
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;
