
import { defineComponent } from "vue";
import { useQuizStore } from "@/store/_DataStore";

export default defineComponent({
  name: "Register",
  data() {
    return {
      isSubmitting: false as boolean,
      errors: [] as any,
      valid: true as boolean,
      name: "" as string,
      promo: false as boolean,
      url: "" as string,
      file: "" as any,
    };
  },
  mounted() {
    this.axios.get(`${process.env.VUE_APP_API_URL}/api/quiz/promo`).then((response) => {
      const store = useQuizStore();
      store.$patch({ promo: response.data.data.promo });
      this.promo = response.data.data.promo;
    });
  },
  methods: {
    onFileChange(e: any) {
      this.file = e.target.files[0];
      this.url = URL.createObjectURL(this.file);
    },
    deleteFile() {
      const input = this.$refs.file as HTMLInputElement;
      this.url = "";
      input.value = "";
    },
    registerUser() {
      this.errors = [];
      this.isSubmitting = true;
      let valid = true;

      const validateName = (name: string) => {
        if (!name.length) {
          return { valid: false, error: "Naam is verplicht" };
        } else if (this.name.length > 30) {
          return { valid: false, error: "Je naam is te lang" };
        }
        return { valid: true, error: null };
      };
      const validName = validateName(this.name);
      this.errors.name = validName.error;

      const validateImage = (file: string) => {
        if (!file) {
          return { valid: false, error: "Selfie is verplicht" };
        }
        return { valid: true, error: null };
      };
      const validImage = validateImage(this.url);
      this.errors.file = validImage.error;

      if (this.promo === true) {
        if (validName.valid == false || validImage.valid == false) {
          valid = false;
          this.isSubmitting = false;
        }
      } else {
        if (validName.valid == false) {
          valid = false;
          this.isSubmitting = false;
        }
      }

      if (valid) {
        const categories = ["hollands", "pop_young", "pop_old", "urban"];
        const randomCategory = categories[Math.floor(Math.random() * categories.length)];
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("name", this.name);
        formData.append("category", randomCategory);

        this.axios
          .post(`${process.env.VUE_APP_API_URL}/api/player`, formData)
          .then((response) => {
            const store = useQuizStore();
            store.$patch({ playerId: response.data.data.playerId });
            store.$patch({ quizSessionId: response.data.data.quizSessionId });
            store.$patch({
              myPlayer: {
                id: response.data.data.playerId,
                name: this.name,
                file: this.file,
              },
            });
            if (this.promo === true) {
              this.$router.push("/waitscreen");
            } else {
              this.$router.push("/question");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    openLink() {
      window.open("https://us.coca-cola.com/cokestudio");
    },
  },
});
