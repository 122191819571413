
import { defineComponent } from "vue";
import { gsap, Bounce } from "gsap";

export default defineComponent({
  name: "TvPulseTopRight",
  data() {
    return {};
  },
  methods: {},
  mounted() {
    gsap.to("#master", 40, {
      rotation: 360,
      transformOrigin: "center center",
      ease: Bounce.easeOut,
      repeat: -1,
      scale: 1.5,
      yoyo: true,
    });
  },
});
