
import { defineComponent } from "vue";
import { useQuizStore } from "@/store/_DataStore";
import StopPromo from "@/components/StopPromo.vue";

export default defineComponent({
  name: "Startup",
  data() {
    return {
      halfway: "" as string,
      quizActive: false as boolean,
      currentQuestion: 0 as number,
    };
  },
  components: {
    StopPromo,
  },
  created() {
    const promoToken = this.$route.query.promoToken;
    console.log(promoToken);

    if(promoToken != 'reMJ7oE8Fx8c'){
      this.$router.push('/');
    }

    const param = this.$route.params.halfway as string;
    if (param == "true") {
      this.quizActive = true;
      this.getQuestion();
    }
  },
  methods: {
    startQuiz() {
      this.getQuestion();
    },
    async getQuestion() {
      const store = useQuizStore();
      const res = await this.axios.get(
        `${process.env.VUE_APP_API_URL}/api/quiz/session/${store.quizSessionId}/question`
      );

      this.currentQuestion = res.data.data.data.questionNumber;
      this.quizActive = true;

      this.startQuestion();
    },
    startQuestion() {
      setTimeout(() => {
        this.endQuestion();
      }, 12000);
    },
    async endQuestion() {
      const store = useQuizStore();
      const res = await this.axios.put(
        `${process.env.VUE_APP_API_URL}/api/quiz/session/${store.quizSessionId}/question/end`,
        {},
        {
          headers: {
            Authorization: `${process.env.VUE_APP_API_SECRET}`,
          },
        }
      );

      if (this.currentQuestion == 3) {
        this.$router.push("/tablet/interimscore?promoToken=reMJ7oE8Fx8c");
      } else if (this.currentQuestion != 6) {
        this.getQuestion();
      } else {
        this.$router.push("/tablet/winner?promoToken=reMJ7oE8Fx8c");
      }
    },
  },
});
