import { defineStore } from 'pinia'

import { IPlayerMe } from '@/interfaces/_ICore'

export const useQuizStore = defineStore('data', {
  state: () => {
    return {
      promo: false as boolean,
      countQuestion: 0,
      players: [],

      // Mobile
      myPlayer: {} as IPlayerMe,
      
      //Return the session ID when promo is true
      sessionId: '',
      currentCategory: '',

      //Return the quissessionId when the promo is false
      quizSessionId: '',
      playerId: '',
      playerScore: '',

      //Store player avatar and name for the chosen players
      tvNameOne: '',
      tvAvatarOne: '',
      tvNameTwo: '',
      tvAvatarTwo: ''
    }
  },
  actions: {
    increment(){
      this.countQuestion++
    }
  },
  getters: {

  }  
})