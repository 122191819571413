
import { defineComponent } from "vue";

export default defineComponent({
  name: "StopPromo",
  data() {
    return {
      stopQuiz: false,
      pauseQuiz: false,
      status: false,
    };
  },
  methods: {
    stopPromoQuiz() {
      const postData = {
        promo: false,
      };
      this.axios
        .put(`${process.env.VUE_APP_API_URL}/api/quiz/flow`, postData, {
          headers: {
            Authorization: `${process.env.VUE_APP_API_SECRET}`,
          },
        })
        .then((response) => {
          const data = response.data.data;
          this.status = false;
          console.log(data);
          this.$router.push("/tablet?promotoken=reMJ7oE8Fx8c");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
});
