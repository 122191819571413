
import { defineComponent } from "vue";
import { useQuizStore } from "@/store/_DataStore";
import StopPromo from "@/components/StopPromo.vue";

export default defineComponent({
  name: "Startup",
  data() {
    return {
      promoStatus: false as boolean,
      promo: false as boolean,
      hideButton: false as boolean,
      wrapperTop: true as boolean,
      categories: [
        { id: 1, name: "Hollands", key: "hollands" },
        { id: 2, name: "Pop Jong", key: "pop_young" },
        { id: 3, name: "Pop Oud", key: "pop_old" },
        { id: 4, name: "Urban/Pop/Hiphop", key: "urban" },
      ],
    };
  },
  components: {
    StopPromo,
  },
  created() {
    const promoToken = this.$route.query.promotoken ?? this.$route.query.promoToken;

    if (promoToken !== "reMJ7oE8Fx8c") {
      this.$router.push("/");
    }

    this.axios.get(`${process.env.VUE_APP_API_URL}/api/quiz/promo`).then((response) => {
      const store = useQuizStore();
      console.log(response.data.data.promo);

      this.promo = response.data.data.promo;
      console.log(this.promo);
    });
  },
  mounted() {
    const postData = {
      promo: false,
    };

    this.axios
      .put(`${process.env.VUE_APP_API_URL}/api/quiz/flow`, postData, {
        headers: {
          Authorization: `${process.env.VUE_APP_API_SECRET}`,
        },
      })
      .then((response) => {
        const data = response.data.data;
        this.promoStatus = false;
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    async startPromo(key: string) {
      try {
        const postData = {
          promo: true,
        };

        const res = await this.axios.put(`${process.env.VUE_APP_API_URL}/api/quiz/flow`, postData, {
          headers: {
            Authorization: `${process.env.VUE_APP_API_SECRET}`,
          },
        });

        const data = res.data.data;
        this.promoStatus = true;
      } catch (error) {
        console.log(error);
      }

      try {
        const postData = {
          category: key,
        };

        const res = await this.axios.post(`${process.env.VUE_APP_API_URL}/api/quiz/session`, postData, {
          headers: {
            Authorization: `${process.env.VUE_APP_API_SECRET}`,
          },
        });

        const store = useQuizStore();
        store.$patch({ quizSessionId: res.data.data.id });
        store.$patch({ currentCategory: key });

        this.$router.push("/tablet/participants?promoToken=reMJ7oE8Fx8c");
      } catch (error) {
        console.log(error);
      }
    },
  },
});
