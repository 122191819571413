
import { defineComponent } from "vue";
import { useQuizStore } from "@/store/_DataStore";

export default defineComponent({
  name: "Result",
  data() {
    return {
      score: 0 as number,
      scoreOpponent: 0 as number,
      amountCorrect: 0,
      promo: false as boolean,
    };
  },
  created() {
    const store = useQuizStore();
    if (store.playerId === "") {
      this.$router.push("/");
    }
  },
  mounted() {
    const store = useQuizStore();
    this.promo = store.promo;
    if (!this.promo) {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}/api/quiz/session/${store.quizSessionId}/results`)
        .then((response) => {
          this.score = response.data.data.result.score;
          this.amountCorrect = response.data.data.result.countCorrectAnswers || 0;
          store.$patch({ playerScore: response.data.data.result.score });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.score = Number(this.$route.params.score);
      this.scoreOpponent = Number(this.$route.params.opponent);
    }
  },
  methods: {
    openLink() {
      window.open("https://us.coca-cola.com/cokestudio");
    },
  },
});
