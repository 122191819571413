
import { defineComponent } from "vue";
import { useQuizStore } from "@/store/_DataStore";

import StopPromo from "@/components/StopPromo.vue";

interface IResult {
  scoreA: number;
  scoreB: number;
}

export default defineComponent({
  name: "Winner",
  components: {
    StopPromo,
  },
  data() {
    const store = useQuizStore();

    return {
      store: store as any,
      score: {} as IResult,
    };
  },
  computed: {
    winner() {
      const winner =
        this.score.scoreA > this.score.scoreB
          ? this.store.players.playerA
          : this.score.scoreA < this.score.scoreB
          ? this.store.players.playerB
          : "tie";
      return winner;
    },
  },
  async created() {
    const promoToken = this.$route.query.promoToken;

    if (promoToken != "reMJ7oE8Fx8c") {
      this.$router.push("/");
    }
    try {
      const store = useQuizStore();
      console.log(store.players);
      const res = await this.axios.get(
        `${process.env.VUE_APP_API_URL}/api/quiz/session/${store.quizSessionId}/results`,
        {
          headers: {
            Authorization: `${process.env.VUE_APP_API_SECRET}`,
          },
        }
      );

      this.score = res.data.data.result;
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async restartPromo() {
      try {
        const store = useQuizStore(); 
        const category = store.currentCategory;
        console.log(category)
        const postData = {
          category: category
        };

        const res = await this.axios.post(
          `${process.env.VUE_APP_API_URL}/api/quiz/session`,
          postData,
          {
            headers: {
              Authorization: `${process.env.VUE_APP_API_SECRET}`,
            },
          }
        );

        store.$patch({ quizSessionId: res.data.data.id });

        this.$router.push("/tablet/participants?promoToken=reMJ7oE8Fx8c");
      } catch (error) {
        console.log(error);
      }

      this.$router.push("/tablet/participants?promoToken=reMJ7oE8Fx8c");
    },
  },
});
