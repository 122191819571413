
import { defineComponent } from "vue";
import { useQuizStore } from "@/store/_DataStore";
import TvPulseMiddle from "@/components/TvPulseMiddle.vue";

export default defineComponent({
  name: "WinnerTV",
  components: {
    TvPulseMiddle,
  },
  data() {
    return {
      status: "" as string,
      pollInterval: null as any,
      nameOne: "" as string,
      avatarOne: "" as string,
      nameTwo: "" as string,
      avatarTwo: "" as string,
      scoreOne: "" as string,
      scoreTwo: "" as string,
      winner: "" as string,
    };
  },
  created() {
    this.waitingStatus();
    const store = useQuizStore();
    this.nameOne = store.tvNameOne;
    this.avatarOne = store.tvAvatarOne;
    this.nameTwo = store.tvNameTwo;
    this.avatarTwo = store.tvAvatarTwo;
  },
  unmounted() {
    clearInterval(this.pollInterval);
  },
  methods: {
    waitingStatus() {
      this.pollInterval = setInterval(async () => {
        try {
          const res = await this.axios.get(
            `${process.env.VUE_APP_API_URL}/api/quiz/session/current/status/0?sb=true`
          );

          const status = res.data.data.status;

          console.log(status);
          if (status == "ended") {
            this.scoreOne = res.data.data.result.playerA;
            this.scoreTwo = res.data.data.result.playerB;

            if (this.scoreOne > this.scoreTwo) {
              this.winner = "A";
              console.log("Player A wins");
            } else if (this.scoreOne === this.scoreTwo) {
              this.winner = "draw";
              console.log("It's a draw");
            } else {
              this.winner = "B";
              console.log("Player B wins");
            }
          } else if (status == "qr") {
            this.$router.push("/tv");
          }
        } catch (error) {
          console.log(error);
        }
      }, 500);
    },
  },
});
