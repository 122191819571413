
import { defineComponent } from "vue";
import { useQuizStore } from "@/store/_DataStore";
import StopPromo from "@/components/StopPromo.vue";

import { IPlayerData } from "@/interfaces/_ICore";

export default defineComponent({
  name: "Participants",
  data() {
    return {
      playerInterval: 0 as number,
      players: [] as IPlayerData[],
      selected: [] as number[],
    };
  },
  components: {
    StopPromo,
  },
  created() {
    const promoToken = this.$route.query.promoToken;

    if (promoToken != "reMJ7oE8Fx8c") {
      this.$router.push("/");
    }
    this.loadPlayers();
  },
  unmounted() {
    clearInterval(this.playerInterval);
  },
  methods: {
    async startQuiz() {
      // Make sure there are 2 players selected
      if (this.selected.length < 2) return false;

      const store = useQuizStore();
      const res = await this.axios.put(
        `${process.env.VUE_APP_API_URL}/api/quiz/session/${store.quizSessionId}/players`,
        { players: this.selected },
        {
          headers: {
            Authorization: `${process.env.VUE_APP_API_SECRET}`,
          },
        }
      );

      store.$patch({
        players: res.data.data.selectedPlayers,
      });

      this.$router.push("/tablet/question?promoToken=reMJ7oE8Fx8c");
    },
    shutDown() {
      this.$router.push("/tablet/startup");
    },
    loadPlayers() {
      const store = useQuizStore();
      this.playerInterval = setInterval(async () => {
        try {
          const res = await this.axios.get(
            `${process.env.VUE_APP_API_URL}/api/quiz/session/${store.quizSessionId}/players`,
            {
              headers: {
                Authorization: `${process.env.VUE_APP_API_SECRET}`,
              },
            }
          );
          this.players = res.data.data.players;
        } catch (error) {
          console.log(error);
        }
      }, 500);
    },
    selectedPlayers(selected: number) {
      // Check if player already selected, if yes, then deselect
      if (this.selected.includes(selected)) {
        const index = this.selected.indexOf(selected);
        this.selected.splice(index, 1);
        return false;
      }

      // Check if already 2 players selected
      if (this.selected.length >= 2) return false;

      // Add selected player to array
      this.selected.push(selected);
    },
  },
});
