
import { defineComponent } from "vue";
import Greypulse from "@/components/Greypulse.vue";
import { useQuizStore } from "@/store/_DataStore";

export default defineComponent({
  name: "Question",
  data() {
    return {
      promo: false as boolean,
      isSubmitting: false as boolean,
      halfway: 'false' as string,
      currentAnswer: null as string | null,
      currentQuestion: {
        id: null,
        question: '',
        answers: []
      },
      progress: 0 as number,
      pollInterval: 0 as number
    };
  },
  components: {
    Greypulse,
  },
  created() {
    const store = useQuizStore();
    const param = this.$route.params.halfway as string;
    
    this.promo = store.promo;
    console.log(this.promo);
    this.halfway = param;

    if (!this.promo) {
      this.getSoloQuestion();
    } else {
      this.getQuestion();
    }
  },
  methods: {
    async getSoloQuestion() {
      this.isSubmitting = false;
      const store = useQuizStore();
      const res = await this.axios.get(
          `${process.env.VUE_APP_API_URL}/api/quiz/session/${store.quizSessionId}/question`
        )
      
      const data = res.data.data.data;
      this.currentQuestion.id = data.id;
      this.currentQuestion.question = data.question;
      this.currentQuestion.answers = data.content;  
    },
    getQuestion() {
      if (this.halfway) this.progress = 3;
      const store = useQuizStore();
      this.pollInterval = setInterval(async () => {
        try {
          const res = await this.axios.get(
            `${process.env.VUE_APP_API_URL}/api/quiz/session/${store.quizSessionId}/status/${store.playerId}`
          );

          const status = res.data.data.status;
         
          if (status == 'question' && this.currentQuestion.id !== res.data.data.question.id) {
            this.progress++;
            this.currentAnswer = null;
         
            this.currentQuestion.id = res.data.data.question.id;
            this.currentQuestion.question = res.data.data.question.question;
            this.currentQuestion.answers = res.data.data.question.content;  
          }

          if (status == "interimResult") {
            this.$router.push({name: "Halfway"})
            clearInterval(this.pollInterval)
            return false;
          }

          if (status == 'ended') {
            const result = res.data.data.result;
            this.endQuiz(result);
            return false;
          }
        } catch(error) {
          console.log(error)
        }
      }, 500)
    },
 
    async submit(clickedValue: string) {
      if(this.promo === true){
        this.currentAnswer = clickedValue;
      }
     if(this.promo === false){
        this.isSubmitting = true;
     }
      
      const store = useQuizStore();
      const postData = {
        answer: clickedValue,
        playerId: store.playerId,
      };

      try {
        const res = await this.axios.post(
            `${process.env.VUE_APP_API_URL}/api/quiz/session/${store.quizSessionId}/question/answer`, postData)

        if (!this.promo) {
          store.countQuestion++;
          this.progress = store.countQuestion;

          if (store.countQuestion === 6) {
            store.$patch({ countQuestion:0 });
            this.$router.push("/result");
          } else {
            this.getSoloQuestion();
          }
        }
      } catch(error) {
        console.log(error)
      }
    },
    endQuiz(result: any) {
      clearInterval(this.pollInterval)
      this.$router.push({name: 'Result', params: {score: result.you, opponent: result.opponent}})
    }
  }
});
