
import { defineComponent } from "vue";
import Redpulse from "@/components/Redpulse.vue";
import { useQuizStore } from "@/store/_DataStore";

import { IPlayerMe } from "@/interfaces/_ICore";

export default defineComponent({
  name: "Waitscreen",
  components: {
    Redpulse,
  },
  data() {
    return {
      status: "waiting" as string,
      pollInterval: 0 as number,
      myPlayer: {} as IPlayerMe,
    };
  },
  created() {
    this.waitingStatus();
  },
  computed: {
    playerAvatar() {
      return URL.createObjectURL(this.myPlayer.file);
    },
  },
  methods: {
    async waitingStatus() {
      const store = useQuizStore();
      this.myPlayer = store.myPlayer;

      try {
        const res = await this.axios.get(
          `${process.env.VUE_APP_API_URL}/api/quiz/session/${store.quizSessionId}/status/${store.playerId}&timestamp=${new Date().getTime()}`
        );

        const status = res.data.data.status;

        if (status == "waiting") {
          this.status = "waiting";
          this.waitingStatus();
        } else if (status == "chosen") {
          this.status = "chosen";
          this.waitingStatus();
        } else if (status == "question") {
          this.$router.push("/question");
        } else {
          this.$router.push("/");
          console.log("ERROR: ", status);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
});
